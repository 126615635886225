export const setResizedBusinessLogo = (base64, maxWidth, stateManager) => {
  const img = new Image();
  img.src = base64;
  img.onload = () => {
    let { width, height } = img;
    let newWidth = width;
    let newHeight = height;

    if (width > maxWidth) {
      const scaleFactor = maxWidth / width;
      newWidth = Math.floor(width * scaleFactor);
      newHeight = Math.floor(height * scaleFactor);
    }

    stateManager((prevState) => ({
      ...prevState,
      businessLogo: base64,
      businessLogoWidth: newWidth,
      businessLogoHeight: newHeight,
    }));
  };
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const convertToHtml = (str) => {
  return str.replace(/\n/g, '<br />');
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.toString().replace(/\D/g, '');
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
};

export const autoMaxZipLength = (value) => {
  const maxLength = 5;
  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }
  return value;
};

export const autoMaxOtpLength = (value) => {
  const maxLength = 6;
  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }
  return value;
};

export const autoFormatUppercase = (value) => {
  return value.toUpperCase();
};

export const autoFormatPhoneNumber = (value) => {
  value = value.replace(/\D/g, '');

  if (value.length > 3 && value.length <= 6) {
    value = `${value.slice(0, 3)}-${value.slice(3)}`;
  } else if (value.length > 6) {
    value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }
  return value;
};

export const autoFormatCapitalize = (value) => {
  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const autoFormatters = {
  autoMaxZipLength,
  autoFormatUppercase,
  autoFormatCapitalize,
  autoFormatPhoneNumber,
};

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const getDateTimestamp = (date) => {
  return new Date(date)
    .toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(', ', ' - ');
};

export const newPatientOption = {
  Label: 'Send to new patient',
  FirstName: '',
  LastName: '',
  EmailAddresses: [
    {
      Address: '',
    },
  ],
  PhoneNumbers: [
    {
      Number: '',
    },
  ],
  Id: 'NEW',
};

export const getDynamicRequired = (formData, required) => {
  if (typeof required === 'object') {
    if (typeof required.value === 'boolean') {
      return required.value && formData[required.name] ? true : false;
    }
    return required.value.includes(formData[required.name]);
  }

  return required;
};

export const setupIndexedDB = async () => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('IntakeDB', 1);
    dbRequest.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('formDataStore')) {
        db.createObjectStore('formDataStore', { keyPath: 'id' });
      }
    };

    dbRequest.onsuccess = (event) => {
      resolve(event.target.result);
    };

    dbRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const saveFormDataToIndexedDB = async (formData, notificationId) => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('IntakeDB', 1);
    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction('formDataStore', 'readwrite');
      const store = transaction.objectStore('formDataStore');
      store.put({ id: `formData_${notificationId}`, data: formData });

      transaction.oncomplete = () => {
        resolve(true);
      };

      transaction.onerror = (error) => {
        reject(error);
      };
    };
  });
};

export const deleteFormDataFromIndexedDB = async (notificationId) => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('IntakeDB', 1);
    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction('formDataStore', 'readwrite');
      const store = transaction.objectStore('formDataStore');
      const deleteRequest = store.delete(`formData_${notificationId}`);

      deleteRequest.onsuccess = () => {
        resolve(true);
      };

      deleteRequest.onerror = (error) => {
        reject(error);
      };
    };

    dbRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const loadFormDataFromIndexedDB = async (notificationId) => {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open('IntakeDB', 1);
    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction('formDataStore', 'readonly');
      const store = transaction.objectStore('formDataStore');
      const getRequest = store.get(`formData_${notificationId}`);

      getRequest.onsuccess = () => {
        resolve(getRequest.result?.data || null);
      };

      getRequest.onerror = (error) => {
        reject(error);
      };
    };

    dbRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const isValidBase64Image = (base64) => {
  if (!base64 || typeof base64 !== 'string' || !base64.startsWith('data:image/')) {
    return false;
  }

  const base64Regex = /^data:image\/[a-zA-Z0-9.-]+;base64,[A-Za-z0-9+/=]+$/;
  return base64Regex.test(base64);
};
