export const stepFields = [
  [
    {
      step: 'Patient',
    },
    {
      name: 'patientSalutation',
      label: 'Title',
      type: 'select',
      required: false,
      isComment: true,
      optionsByDynamicStateKey: 'salutations',
    },
    {
      name: 'patientFirstName',
      label: 'Legal First Name',
      type: 'text',
      required: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientLastName',
      label: 'Legal Last Name',
      type: 'text',
      required: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientPreferredName',
      label: 'Preferred Name',
      type: 'text',
      required: false,
      isComment: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientEmail',
      label: 'Email Address',
      type: 'email',
      required: true,
      pattern: '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$',
    },
    {
      name: 'patientPhone',
      label: 'Phone',
      type: 'tel',
      required: true,
      pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      helperText: 'Phone format: XXX-XXX-XXXX',
      autoFormat: 'autoFormatPhoneNumber',
      maxLength: 12,
    },
    {
      name: 'patientGender',
      label: 'Gender',
      type: 'select',
      required: true,
      options: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
    },
    {
      name: 'patientDateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      required: true,
    },
    {
      name: 'patientStreetAddress',
      label: 'Street Address',
      type: 'text',
      required: true,
    },
    {
      name: 'patientAddressLine2',
      label: 'Address Line 2',
      type: 'text',
      required: false,
    },
    {
      name: 'patientCity',
      label: 'City',
      type: 'text',
      required: true,
    },
    {
      name: 'patientState',
      label: 'State',
      type: 'select',
      required: true,
      optionsByDynamicStateKey: 'states',
    },
    {
      name: 'patientZip',
      label: 'Zip',
      type: 'number',
      required: true,
      pattern: '^\\d{5}$',
      autoFormat: 'autoMaxZipLength',
    },
    {
      type: 'divider',
    },
    // {
    //   name: 'patientDentalOfficeLocation',
    //   label: 'Dental Office Location',
    //   type: 'select',
    //   required: true,
    //   isComment: true,
    //   optionsByDynamicStateKey: 'locations',
    // },
    {
      name: 'patientSchoolName',
      label: 'School Name (if applicable)',
      type: 'select-text',
      required: false,
      isComment: true,
      optionsByDynamicStateKey: 'schools',
    },
    {
      name: 'patientGrade',
      label: 'Grade (if applicable)',
      type: 'text',
      required: false,
      isComment: true,
      maxLength: 2,
    },
    {
      name: 'patientExtracurricularActivities',
      label: 'List any sports, hobbies, or musical instruments played',
      type: 'textarea',
      required: false,
      isComment: true,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'patientFriendsFamilyAtOffice',
      label: 'Do you have any friends or family currently at Imagine?',
      type: 'text',
      required: false,
      isComment: true,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'patientHearAboutUs',
      label: 'How did you hear about us?',
      type: 'text',
      required: false,
      isComment: true,
    },
    {
      name: 'patientPreviousOrthodonticConsultation',
      label: 'Have you had a previous orthodontic consultation?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientPreviousOrthodonticConsultationWhenWhere',
      label: 'If yes, when/where?',
      type: 'text',
      required: {
        name: 'patientPreviousOrthodonticConsultation',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'patientPreviousOrthodonticConsultation',
        value: ['No'],
      },
    },
    {
      name: 'patientOrthodonticIssuesOrConcerns',
      label: 'What are your orthodontic issues or concerns?',
      type: 'textarea',
      required: true,
      isComment: true,
    },
    {
      type: 'divider',
    },
    {
      name: 'patientIsResponsibleParty',
      label: 'Is the patient the same as the responsible party?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      marginBottom: 2,
    },
    {
      name: 'responsibleRelationshipId',
      label: 'Relationship to Patient',
      type: 'select',
      optionsByDynamicStateKey: 'relationships',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      isComment: true,
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleSalutation',
      label: 'Title',
      type: 'select',
      required: false,
      isComment: true,
      optionsByDynamicStateKey: 'salutations',
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleFirstName',
      label: 'Legal First Name',
      type: 'text',
      autoFormat: 'autoFormatCapitalize',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleLastName',
      label: 'Legal Last Name',
      type: 'text',
      autoFormat: 'autoFormatCapitalize',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleEmail',
      label: 'Email Address',
      type: 'email',
      pattern: '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsiblePhone',
      label: 'Phone',
      type: 'tel',
      pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      helperText: 'Phone format: XXX-XXX-XXXX',
      autoFormat: 'autoFormatPhoneNumber',
      maxLength: 12,
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleGender',
      label: 'Gender',
      type: 'select',
      options: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleDateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleStreetAddress',
      label: 'Street Address',
      type: 'text',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleAddressLine2',
      label: 'Address Line 2',
      type: 'text',
      required: false,
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleCity',
      label: 'City',
      type: 'text',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleState',
      label: 'State',
      type: 'select',
      optionsByDynamicStateKey: 'states',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleZip',
      label: 'Zip',
      type: 'number',
      pattern: '^\\d{5}$',
      autoFormat: 'autoMaxZipLength',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      type: 'divider',
    },
    {
      name: 'photoIDFront',
      label: 'Upload a Valid Photo ID (Front)',
      type: 'file',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Photo Required',
    },
    {
      name: 'photoIDBack',
      label: 'Upload a Valid Photo ID (Back)',
      type: 'file',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Photo Required',
    },
    {
      name: 'responsibleEmployerId',
      label: 'Employer',
      type: 'select-text',
      required: true,
      isComment: true,
      optionsByDynamicStateKey: 'employers',
    },
    {
      name: 'responsibleOccupation',
      label: 'Occupation',
      type: 'text',
      required: true,
      isComment: true,
    },
    {
      name: 'responsibleIfDivorceIWhoIsCustodialParent',
      label: 'If divorce is involved, who is the Custodial Parent?',
      type: 'text',
      required: false,
      isComment: true,
      marginBottom: 2,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'responsibleMayPatientInformationBeReleasedToNonCustodialParent',
      label: 'May patient information be released to the non-custodial parent?',
      type: 'radio',
      required: {
        name: 'responsibleIfDivorceIWhoIsCustodialParent',
        value: true,
      },
      isComment: true,
      hidden: {
        name: 'responsibleIfDivorceIWhoIsCustodialParent',
        value: true,
      },
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
  ],
  [
    {
      step: 'Dental',
    },
    {
      name: 'primaryDentistName',
      label: 'Primary Dentist Name',
      type: 'text',
      required: false,
      isComment: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'otherDentalSpecialists',
      label: 'Other Dental Specialists',
      type: 'text',
      required: false,
      isComment: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      type: 'divider',
    },
    {
      name: 'bracesOrInvisalign',
      label: 'Does the patient prefer Braces or Invisalign?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Braces', value: 'Braces' },
        { label: 'Invisalign', value: 'Invisalign' },
      ],
    },
    {
      name: 'anyoneConsideringOrthodontics',
      label: 'Is anyone else in the family considering orthodontics?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'whoConsideringOrthodontics',
      label: 'If yes, who?',
      type: 'text',
      required: {
        name: 'anyoneConsideringOrthodontics',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'anyoneConsideringOrthodontics',
        value: ['No'],
      },
    },
    {
      name: 'hasPatientRegularCheckups',
      label: 'Has the patient had regular checkups?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'lastDentalExamDate',
      label: 'Last Dental Exam Date',
      type: 'date',
      required: {
        name: 'hasPatientRegularCheckups',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'hasPatientRegularCheckups',
        value: ['No'],
      },
      sx: { minHeight: '24px' },
    },
    {
      name: 'lastDentalXRaysDate',
      label: 'Last Dental X-Rays Date',
      type: 'date',
      required: {
        name: 'hasPatientRegularCheckups',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'hasPatientRegularCheckups',
        value: ['No'],
      },
      sx: { minHeight: '24px' },
    },
    {
      name: 'satisfiedWithPastDentistry',
      label: 'Have you been satisfied with past dentistry?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'ifNotSatisfiedWithPastDentistryExplain',
      label: 'If no, please explain',
      type: 'textarea',
      required: {
        name: 'satisfiedWithPastDentistry',
        value: ['No'],
      },
      isComment: true,
      hidden: {
        name: 'satisfiedWithPastDentistry',
        value: ['Yes'],
      },
      marginBottom: 2,
    },
    {
      type: 'divider',
    },
    {
      name: 'patientAnyOfTheFollowingPain',
      label: 'Does the patient currently have, or have had any of the following?',
      type: 'checkbox',
      required: true,
      isComment: true,
      options: [
        { label: 'Thumb Habit', value: 'ThumbHabit' },
        { label: 'Finger Habit', value: 'FingerHabit' },
        { label: 'Head Injury', value: 'HeadInjury' },
        { label: 'Neck Injury', value: 'NeckInjury' },
        { label: 'Nail Biting', value: 'NailBiting' },
        { label: 'Jaw Pain', value: 'JawPain' },
        { label: 'Joint Pain', value: 'JointPain' },
        { label: 'Head Pain', value: 'HeadPain' },
        { label: 'Neck Pain', value: 'NeckPain' },
        { label: 'Periodontal Disease', value: 'PeriodontalDisease' },
        { label: 'Cold Sores', value: 'ColdSores' },
        { label: 'Clenching', value: 'Clenching' },
        { label: 'Grinding', value: 'Grinding' },
        { label: 'Gum Surgery', value: 'GumSurgery' },
        { label: 'Food Traps', value: 'FoodTraps' },
        { label: 'None', value: 'None' },
      ],
      marginBottom: 2,
    },
    {
      name: 'patientOtherDentalInformation',
      label: 'Is there any other dental information we should know about?',
      type: 'text',
      required: false,
      isComment: true,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
  ],
  [
    {
      step: 'Medical',
    },
    {
      name: 'patientPrimaryPhysiciansName',
      label: "Primary Physician's Name",
      type: 'text',
      required: true,
      isComment: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientOverallHealth',
      label: "Patient's Overall Health",
      type: 'select',
      required: true,
      isComment: true,
      options: [
        { label: 'Excellent', value: 'Excellent' },
        { label: 'Good', value: 'Good' },
        { label: 'Poor', value: 'Poor' },
      ],
    },
    {
      type: 'divider',
    },
    {
      name: 'patientHaveAllergies',
      label: 'Does the patient have any allergies (drugs / food / pollen)?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientListOfAllergies',
      label: 'If yes, please list',
      type: 'text',
      required: {
        name: 'patientHaveAllergies',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'patientHaveAllergies',
        value: ['No'],
      },
    },
    {
      name: 'patientCurrentMedicalCare',
      label: 'Is the patient currently under medical care?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientCurrentMedicalCareWhereWhen',
      label: 'If yes, where/when?',
      type: 'text',
      required: {
        name: 'patientCurrentMedicalCare',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'patientCurrentMedicalCare',
        value: ['No'],
      },
    },
    {
      name: 'patientCurrentMedications',
      label: 'Is the patient currently taking any medications?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientListOfMedications',
      label: 'If yes, please list',
      type: 'text',
      required: {
        name: 'patientCurrentMedications',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'patientCurrentMedications',
        value: ['No'],
      },
    },
    {
      name: 'patientBeenHospitalized',
      label: 'Has the patient ever been hospitalized?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientBeenHospitalizedWhereWhen',
      label: 'If yes, where/when?',
      type: 'text',
      required: {
        name: 'patientBeenHospitalized',
        value: ['Yes'],
      },
      isComment: true,
      hidden: {
        name: 'patientBeenHospitalized',
        value: ['No'],
      },
    },
    {
      type: 'divider',
    },
    {
      name: 'patientConditions',
      label: 'Does the patient currently have, or have had any of the following?',
      type: 'checkbox',
      required: true,
      isComment: true,
      options: [
        { label: 'Adenoid Removed', value: 'AdenoidRemoved' },
        { label: 'Drug History', value: 'DrugHistory' },
        { label: 'Major Surgery', value: 'MajorSurgery' },
        { label: 'AIDS (HIV)', value: 'AIDS_HIV' },
        { label: 'Epilepsy / Seizures', value: 'EpilepsySeizures' },
        { label: 'Nasal / Airway Problems', value: 'NasalAirwayProblems' },
        { label: 'Arthritis', value: 'Arthritis' },
        { label: 'Heart Problems', value: 'HeartProblems' },
        { label: 'Sinus Problems', value: 'SinusProblems' },
        { label: 'Asthma', value: 'Asthma' },
        { label: 'Hepatitis A, B, or C', value: 'Hepatitis' },
        { label: 'Speech Problems', value: 'SpeechProblems' },
        { label: 'Auto Accident', value: 'AutoAccident' },
        { label: 'High Blood Pressure', value: 'HighBloodPressure' },
        { label: 'Tobacco Usage', value: 'TobaccoUsage' },
        { label: 'Bleeding Disorders', value: 'BleedingDisorders' },
        { label: 'Immune Disorders', value: 'ImmuneDisorders' },
        { label: 'Tonsils Removed', value: 'TonsilsRemoved' },
        { label: 'Cancer', value: 'Cancer' },
        { label: 'Kidney Problems', value: 'KidneyProblems' },
        { label: 'Tuberculosis', value: 'Tuberculosis' },
        { label: 'Cosmetic Surgery', value: 'CosmeticSurgery' },
        { label: 'Liver Problems', value: 'LiverProblems' },
        { label: 'Tubes in Ears', value: 'TubesInEars' },
        { label: 'Diabetes', value: 'Diabetes' },
        { label: 'Lung Problems', value: 'LungProblems' },
        { label: 'Venereal Disease', value: 'VenerealDisease' },
        { label: 'None', value: 'None' },
      ],
    },
  ],
  [
    {
      step: 'Insurance',
    },
    {
      name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
      label: 'Do you have insurance coverage?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'insuranceCardFront',
      label: 'Upload an Insurance Card (Front)',
      type: 'file',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      isComment: true,
      isDoc: true,
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
      helperText: 'Valid Photo Required',
    },
    {
      name: 'insuranceCardBack',
      label: 'Upload an Insurance Card (Back)',
      type: 'file',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      isComment: true,
      isDoc: true,
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
      helperText: 'Valid Photo Required',
    },
    {
      name: 'insuranceCarrierName',
      label: 'Insurance Company Name',
      type: 'select-text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      isComment: true,
      optionsByDynamicStateKey: 'insurances',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
    {
      name: 'insuranceCarrierPolicyNumber',
      label: 'Insurance Policy #',
      type: 'text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      autoFormat: 'autoFormatUppercase',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
    {
      name: 'insuranceCarrierGroupNumber',
      label: 'Insurance Group / Member #',
      type: 'text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      autoFormat: 'autoFormatUppercase',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
  ],
  [
    {
      step: 'Consent',
      pdfLabel: 'Patient Consent',
    },
    {
      name: 'legalComplianceConsent',
      label: 'I am 18 years of age or older, and I am either the patient, the parent, or the legal guardian.',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'element',
      content: `
        <div>
          <h1>
            Patient Consent to Use, Disclosure, and Request Health Information for Treatment, Payment, or Healthcare Operations,
            and Acknowledgement of Opportunity to Read and Receive the Health Information Privacy Practices
          </h1>
          <p>
            As part of your oral healthcare, Imagine Orthodontic Studio originates and maintains paper and / or electronic records
            describing your health history, symptoms, examinations, test results, diagnoses, treatment, and any plans for future
            care or treatment. We use this information to:
          </p>
          <ul>
            <li>Plan your care and treatment</li>
            <li>Communicate with other health professionals who contribute to your care and / or treatment</li>
            <li>Submit your diagnosis and treatment information for payment from insurance or others</li>
          </ul>
          <p>
            By signing this document, and “<u>only as permitted by State or Federal law</u>”, you are giving this practice your
            consent to do the following:
          </p>
          <ul>
            <li>
              To disclose, as may be necessary, your health information to other healthcare providers (such as, referrals to or
              consultation with other healthcare professionals, laboratories, hospitals, etc.) for your treatment and / or
              healthcare
            </li>
            <li>
              To request from other healthcare entities (i.e. doctors, dentists, hospitals, labs, imaging centers, etc.) specific
              healthcare information we may need for planning your care and treatment
            </li>
            <li>
              To submit your diagnosis and treatment information to insurance company(s), other agencies and/or individual(s) for
              payment of services
            </li>
            <li>
              Leave appointment reminders or information, we believe necessary for treatment or payment, with a family member or
              on an answering machine. The information, will be the minimum necessary in our professional judgment
            </li>
            <li>
              Discuss your health information (only as necessary in our judgment) with family members or other persons who are or
              may be involved with your healthcare treatment or payments
            </li>
            <li>
              Please list by name and relationship any person with whom we may not share your health or payment information (based
              on professional judgment, this practice has the right to not honor your request):
            </li>
          </ul>
        </div>
      `,
    },
    {
      name: 'declineSharingInfoTo',
      label: 'List of whom we may not share with',
      type: 'text',
      required: false,
      isComment: true,
      autoFormat: 'autoFormatCapitalize',
      marginTop: -1,
    },
    {
      type: 'element',
      content: `
        <div>
          <p>
            We will make available to you our <strong>“Notice of Privacy Practice”</strong> that provides a more complete
            description of health information uses and disclosures as required by the HIPAA standard. You have the following
            rights:
          </p>
          <ul>
            <li>
              The right to read the <strong>“Patient Health Information Privacy Practices”</strong> prior to signing this consent
            </li>
            <li>
              The right to request an electonric or paper copy of the <strong>“Patient Health Information Privacy Practices”</strong> for your own personal use
            </li>
          </ul>
          <p>
            <strong>I fully understand and agree to this consent and acknowledge the above rights and disclosures.</strong>
          </p>
        </div>
      `,
    },
    {
      name: 'hippaConsentSignature',
      label: 'Signature',
      type: 'signature',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Signature Required',
    },
  ],
  [
    {
      step: 'Consent',
      pdfLabel: 'Informed Consent',
    },
    {
      type: 'element',
      content: `
        <div>
          <h1>New Patient Informed Consent</h1>
          <p>
            The following information is important for you to have and understand. This information is routinely shared with all
            of our patients. We are excited about creating beautiful and healthy smiles. We feel that anyone considering
            orthodontics should understand that orthodontic treatment has some risks and limitations.
          </p>
          <br />
          <p>
            Our goal is to create the ideal smile, and we will do everything in our power to achieve that result. In dealing with
            the many differences in growth, development, genetics, and patient cooperation, it is important to realize that
            perfection is not always possible. Sometimes, we must accept a functionally and aesthetically adequate result.
          </p>
        </div>
      `,
    },
    {
      name: 'patientInformedConsent',
      label: 'Please check each space below:',
      type: 'checkbox',
      allRequired: true,
      boldLabel: true,
      isComment: true,
      oneColumn: true,
      options: [
        {
          label: `It is a fact that teeth are constantly changing position throughout life with or without braces,
and it is not uncommon for slight alignment irregularities to occur over time. This is true with even
the most faithful retainer wear. The more irregular the teeth were before treatment, the more likely
they will be to move after braces. Abnormal muscle function may also affect stability. It is possible
(though uncommon) that additional treatment may be suggested later on in life.`,
          value: '1',
        },
        {
          label: `Sometimes, although rarely, the nerve of a tooth may die. This is much more likely in teeth with
"deep" decay or some other form of trauma. If the nerve dies, root canal (endodontic) therapy will
be necessary and is performed by another dentist. Some patients may have an increased
likelihood of developing periodontal (gum) disease and / or changes in the shape and health of
the gum tissue (loss of interdental papilla) or thin gums.`,
          value: '2',
        },
        {
          label: `The patient's most important responsibility is to keep the teeth and appliances clean and
intact. In some instances, the enamel of some teeth can be affected by prolonged wearing of
appliances and poor oral hygiene. If, after thorough instruction, the patient is unmotivated to
improve hygiene, treatment will be terminated and the braces removed. The patient's general
welfare supersedes any orthodontic care.`,
          value: '3',
        },
        {
          label: `Orthodontic treatment may at times cause some discomfort. Some patients have or may
develop allergies to dental materials and / or orthodontic appliances which may necessitate
early termination of treatment and compromise of the result.`,
          value: '4',
        },
        {
          label: `Root resorption or the dissolving of the root of a tooth may occur during orthodontic
treatment. This process is not predictable. It can be due to many factors including trauma,
impaction, endocrine disorders, and other systemic and idiopathic reasons. Under healthy
circumstances, shortened roots will not significantly affect tooth longevity but can adversely
affect longevity in conjunction with gum disease.`,
          value: '5',
        },
        {
          label: `Orthodontic treatment may, in some circumstances, adversely affect the temporo-mandibular
joint (TMJ). Correct alignment of the teeth and bite appear to improve tooth related causes of TMJ
pain, but not in all cases. TMJ problems are very rarely caused by a single circumstance and
appear to be adversely affected by tension, stress and trauma.`,
          value: '6',
        },
        {
          label: `Abnormal and disproportionate growth of the jaws occurs in some people and is
unpredictable. This type of jaw growth can affect jaw relation and may require changing the
original treatment objectives or accepting a compromised result. Skeletal growth disharmony is a
biological process and is beyond the control of the orthodontist.`,
          value: '7',
        },
        {
          label: `If extraction of teeth, exposure of impacted teeth, or orthognathic (jaw) surgery are
suggested as options in conjunction with orthodontic treatment, there may be additional risks
associated with these treatments and they should be discussed thoroughly with the dentist or surgeon who performs these procedures.`,
          value: '8',
        },
        {
          label: `Treatment times are estimates only and can be delayed due to: poor patient cooperation,
poor oral hygiene, broken appliances, missed appointments and lack of facial growth. Significantly
delayed treatment times adversely affect the desired result. Each patient's treatment is on an
individual basis.`,
          value: '9',
        },
        {
          label: `While infrequent and usually of minor consequence, it is possible that injury from appliances
may occur such as swallowing, aspiration, enamel fracture or increased likelihood of
decalcification and / or dental caries (cavities) due to poor brushing.`,
          value: '10',
        },
        {
          label: `Some preexisting conditions such as congenitally misshaped or missing teeth may require
additional dental restorative treatment or implants (not through this office) and may compromise
an ideal result.`,
          value: '11',
        },
        {
          label: `Consent to use records: I hereby give my permission for the use of orthodontic records,
including photographs, made in the process of examinations, treatment, and retention for
purposes of professional consultations, research, education, social media or publication in
professional journals.`,
          value: '12',
        },
        {
          label: `Consent to communication from Imagine Orthodontic Studio: I hereby give my permission
to send and / or receive confidential information in regards to a patient by way of phone,
email, or text. In addition, I give permission to receive marketing information by way of phone,
email, or text.`,
          value: '13',
        },
        {
          label: `It is important to understand that the insurance contract is between the Insurance Company
and the insured member. Our office will gladly submit your insurance claim to your insurance
carrier, as a courtesy to you.`,
          value: '14',
        },
        {
          label: `Payment for services rendered in this practice is your responsibility regardless of your insurance
coverage.`,
          value: '15',
        },
        {
          label: `If for any reason your Insurance Company does not pay the Estimated Insurance Benefit, you
will be responsible for the remaining insurance balance in addition to all scheduled payments.`,
          value: '16',
        },
        {
          label: `It will take up to 24 full months for insurance to pay the full estimated benefits. In the event
that your insurance changes or terminates during treatment, any unpaid balance will be your
responsibility. It is the patient / parent's responsibility to notify our office of any changes to your
insurance.`,
          value: '17',
        },
        {
          label: `In the event that this account is placed with a collection agency, the patient / responsible
party will be responsible for the collection fees, reasonable attorney's fees and court fees.`,
          value: '18',
        },
      ],
    },
    {
      type: 'element',
      content: `
        <div>
          <p>
            <strong>ACKNOWLEDGEMENT OF CONSENT</strong>
          </p>
          <br />
          <p>
            <strong>
              I hereby acknowledge that the major treatment considerations and potential risks of orthodontic treatment have been
              presented to me. I have read and understand the above information and I have been given the opportunity to ask
              questions regarding the proposed treatment and the information on this form. I understand that orthodontic treatment
              is elective and that other choices may include restorative dental treatment or no treatment.
            </strong>
          </p>
          <br />
          <p>
            <strong>I have read and understand the above informed consent letter:</strong>
          </p>
        </div>
      `,
    },
    {
      name: 'patientInformedConsentSignature',
      label: 'Patient / Responsible Party Signature',
      type: 'signature',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Signature Required',
    },
    {
      name: 'witnessInformedConsentSignature',
      label: 'Witness Signature',
      type: 'signature',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Signature Required',
    },
  ],
  [
    {
      step: 'Consent',
      pdfLabel: 'Medicaid Consent',
    },
    {
      type: 'element',
      content: `
        <div>
          <h1>Policies For Patients Who Are Approved By Medicaid</h1>
          <p>Dear Patient / Parent or Legal Guardian,</p>
          <br />
          <p>
            Welcome! Thank you for choosing Imagine Orthodontic Studio for your orthodontic treatment. Creating a beautiful smile
            takes time and commitment. The patient, orthodontist, and office team must work together to achieve the best results.
            Please read this information carefully, which will help us ensure a good experience during your treatment.
          </p>
          <ol>
            <li>
              Medicaid will cover a total of 24 months from the date you start treatment, which includes 24 monthly adjustments.
              It is recommended to start treatment immediately so as not to exceed its allowed time, as additional treatment time
              will not be covered.
            </li>
            <li>
              You must remain active under your Medicaid policy <u>throughout your entire treatment</u> in order to have your
              treatment covered. Please do not call to cancel or stop coming to your appointments, or you will be responsible for
              the remaining balance.
            </li>
            <li>
              Oral hygiene is extremely important during your treatment. We will give you the necessary oral hygiene instructions
              during the treatment to keep your teeth and gums healthy. If your gums are not healthy, we will remove the wires
              until your teeth and gums return to normal. In some cases, the situation may be reported to the State and we will be
              forced to remove the brackets, thus ending your treatment.
            </li>
            <li>
              Do not be late for your appointments, or cancel, or change, your appointments unless it is an emergency, or Medicaid
              will not cover your treatment. <u>Please be on time for your appointments</u>. After a 15-minute delay, it will be
              documented in your dental record and, at the doctor's discretion, the appointment may be rescheduled. Patients who
              do not show up for their appointment and do not call to cancel it in advance, may be responsible for additional
              fees.
            </li>
          </ol>
          <p>
            <strong>Other charges not covered by Medicaid:</strong>
          </p>
          <br />
          <p>
            Our office may charge Medicaid patients for additional services that are the result of poor cooperation or failure to
            fulfill the responsibility of the patient / parent or guardian during treatment. If any of the following occurs, the
            charges will have to be paid (cash, check, or credit card) by the patient / parent or guardian before the patient is
            seen again at our office.
          </p>
          <ol>
            <li>
              Repeated damage to brackets / wires. After five (5) detached brackets or damaged / lost wires there will be a cost
              of <u>$15</u> for each replacement.
            </li>
            <li>
              Loss or damage of brackets (braces), appliances, retainers, etc. It is the patient's responsibility to properly care
              for and maintain the appliances and use them in accordance with the orthodontist's instructions. Replacing and
              repairing these appliances is not covered by Medicaid, and you will be personally responsible for any additional
              costs.
            </li>
            <li>
              Any additional visits required after the time authorized by Medicaid for your treatment will cost you <u>$80</u> per
              visit, and <u>$600</u> for your retainers (unless enrolled in the Imagine Retainer Assurance). Prolonged treatment
              time may be due to:
              <ol>
                <li>
                  Failure to cooperate (for example: not using elastics or devices as directed, excessive damage to devices,
                  constant deficiency in oral hygiene, etc.)
                </li>
                <li>Missing your scheduled appointments</li>
                <li>
                  Not following the required dental treatment before or during your orthodontic treatment (extractions, impacted
                  teeth surgery, fillings / resins, cleanings, etc.)
                </li>
              </ol>
            </li>
          </ol>
          <p>The situations mentioned above can result in the termination of orthodontic treatment.</p>
          <br />
          <p>
            <u>Our offices have also adopted the following rules:</u>
          </p>
          <ul>
            <li>Patients must come in for an appointment approximately every four (4) weeks.</li>
            <li>Food or drinks are not allowed in the office or clinic.</li>
            <li>
              In order to keep noise and distractions to a minimum, please turn off your phone in the clinical treatment area.
            </li>
            <li>An adult person (parent or guardian) must accompany the patient to the appointment.</li>
            <li>
              To protect the privacy of our other patients (HIPPA), only the patient should be taken to the clinical treatment
              area. We will call the parent / guardian if the orthodontist needs to speak with them, or you may request to speak
              to the doctor if you desire.
            </li>
          </ul>
          <pdf-break></pdf-break>
          <p>
            <strong>
              I certify that I have read, understood and will comply with the content of this agreement. I give my consent to
              perform orthodontic treatment.
            </strong>
          </p>
          <br />
          <p>
            <strong>
              By signing below, I also acknowledge and agree that I will bring my child to their scheduled monthly visits in order
              for Medicaid to continue covering my child's treatment.
            </strong>
          </p>
        </div>
      `,
    },
    {
      name: 'patientMedicaidPoliciesSignature',
      label: 'Patient / Responsible Party Signature',
      type: 'signature',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Signature Required',
    },
  ],
  [
    {
      step: 'Consent',
      pdfLabel: 'Digital X-Ray Consent',
    },
    {
      type: 'element',
      content: `
        <div>
          <h1>Digital X-Ray Consent</h1>
          <p>
            Dental radiographs are a part of our comprehensive orthodontic examination. Modern dental x-ray equipment uses
            extremely low-dose radiation. Diagnostic x-rays provide the dentists with valuable information about your teeth and
            supporting bone that cannot be evaluated otherwise. Our office takes the minimum x-rays to allow us to do a thorough
            exam for each patient. All patients will receive a Panoramic and Cephalometric x-ray. Without these x-rays, we cannot
            do a complete exam of the entire mouth and jaw. We may also take photos of our patients as part of their permanent
            record. We will not release these photos to anyone without your permission.
          </p>
          <br />
          <p>
            <strong>Patient Consent to X-Ray (18 and over)</strong>
          </p>
          <br />
          <p>I hereby authorize the performance of diagnostic x-rays. Imagine Orthodontic Studio, LLC has requested.</p>
          <br />
          <p>At this time, I know of no other condition which the taking of x-rays would further complicate.</p>
        </div>
      `,
    },
    {
      name: 'patientDigitalXrayConsentSignature',
      label: 'Patient / Responsible Party Signature',
      type: 'signature',
      required: true,
      isComment: true,
      isDoc: true,
      helperText: 'Valid Signature Required',
    },
  ],
  [
    {
      step: 'Finish',
      pdfLabel: 'Social Media Consent',
    },
    {
      type: 'element',
      content: `
        <div>
          <h1>Photo and Video Release Consent</h1>
          <p>
            The undersigned, do hereby authorize Imagine Orthodontic Studio, its affiliated practices, subsidiaries, parent
            companies, (together the “company”) permission to use, reuse, publish, and republish the likeness of myself, or my
            child (together the “patient”), in any and all of its publications including, without limitation, videos, photographic
            portraits or pictures of the patient made through any medium, and in any and all media now or hereafter known,
            including the internet, for art, advertising, trade, printed materials, or digital / electronic formats. Additionally,
            the company may publish content of the patient's name, name of school or business, city of residence, professional
            affiliations, and extracurricular activities for the purposes of promoting and advertising the company and its
            orthodontic or dental services.
          </p>
          <br />
          <p>
            The patient understands and agrees that any photographs or videos using the patient's likeness will become property of
            the company and will not be returned. Patient hereby authorizes the company on behalf of any of the entities /
            practices it supports permission to edit, alter, copy, exhibit, publish, or distribute the patient's likeness for the
            purpose of publicizing or any related, lawful purpose. Patient agrees to waive the right to inspect and approve the
            final product, wherein the patient's likeness appears. Further, patient waives any right to royalties or other
            compensation related or arising to the use of the patient's likeness.
          </p>
          <br />
          <p>
            In giving this consent, patient releases the advertising and marketing partners of the company, and the company
            itself, from liability for any violation of any personal or proprietary right patient may have in connection with such
            sale, reproduction, or use of my name and images.
          </p>
          <br />
          <p>
            Patient may cancel this authorization to the extent allowed by law. If patient chooses to do so, patient understands
            that the company may have already used photograph(s), video(s) or digital images prior to me cancelling this
            authorization, which would not prohibit any release done prior to the date of cancellation.
          </p>
          <br />
          <p>
            Patient also understands that patient may refuse to consent, and that the patient's dental and/or orthodontic
            treatment will not be affected or influenced by whether or not patient signs this authorization.
          </p>
          <br />
          <p>
            Patient hereby warrant that patient are a legally competent adult and a parent or legally appointed guardian of the
            patient, and that patient has every right to contract for the patient in the above regard.
          </p>
        </div>
      `,
    },
    {
      name: 'socialMediaConsent',
      label: 'Do you consent?',
      type: 'radio',
      required: true,
      isComment: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'socialMediaConsentSignature',
      label: 'Patient / Responsible Party Signature',
      type: 'signature',
      isComment: true,
      isDoc: true,
      required: {
        name: 'socialMediaConsent',
        value: ['Yes'],
      },
      hidden: {
        name: 'socialMediaConsent',
        value: ['No'],
      },
      helperText: 'Valid Signature Required',
    },
  ],
];

export const calculateStepsStatus = (completedSteps, formComplete, activeStep) => {
  if (formComplete) {
    return 'Form Completed';
  }

  if (completedSteps.length === 0 && activeStep === 0) {
    return 'Link Clicked';
  }

  if (completedSteps.includes(activeStep - 1)) {
    return `Step ${activeStep} Completed`;
  }

  return `In Progress`;
};

export const getAllStepFields = () => {
  return stepFields.flat().filter((f) => !!f.name);
};

export const getCommentFields = () => {
  return getAllStepFields().filter((item) => item.isComment === true);
};

export const getDocFieldNames = () => {
  return getAllStepFields()
    .filter((item) => item.isDoc === true)
    .map((file) => file.name);
};

export const getPdfPages = () => {
  return stepFields.filter((subArray) => subArray.some((obj) => obj.pdfLabel !== undefined));
};
