import { Grid2 as Grid, Paper } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';

import { formatPhoneNumber } from '../../helpers';
import Footer from '../../components/footer';

import styles from './index.module.css';

const NotFound = () => {
  return (
    <main className={styles.main}>
      <header className={styles.header}>
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid>
            <Link to="/admin/dashboard">
              <img
                src="/health-of-your-space.png"
                title="Health of Your Space"
                alt="Health of Your Space"
                className={styles.logo}
                width={188}
                height={50}
              />
            </Link>
          </Grid>
          <Grid>
            <h2>
              <a href={`tel:407-485-6148`}>
                <PhoneIcon /> {formatPhoneNumber('407-485-6148')}
              </a>
            </h2>
          </Grid>
        </Grid>
      </header>
      <Paper elevation={2} className={styles.paper}>
        <h1>
          <span>404</span>
          <br />
          Page Not Found
        </h1>
        <p>The page you are looking for does not exist.</p>
      </Paper>
      <Footer />
    </main>
  );
};

export default NotFound;
